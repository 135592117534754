<template>
    <div class="card mb-4" style="height: 520px;">

        <div class="card-header pb-0">
            <h6>Service Details</h6>
            <AddForm />
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
                <table
                    class=" table align-items-center w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                    <thead class="text-xs text-gray-900 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Product name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Description
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Date
                            </th>
                           
                           
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="bg-white dark:bg-gray-800" v-for="(items, index) in service" :key="index">
                           
                            <td>
                                <div class="d-flex px-1 py-1">
                                    <div>
                                        <soft-avatar class="me-2" :img="items.image" alt="kal" border-radius="lg"
                                            shadow="regular" />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ items.name }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                {{ items.short_desc }}
                            </td>
                            <td class="px-6 py-4">
                                {{ moment(items.created_at).calendar()}}
                            </td>
                            <td class="align-middle justify-content-between">
                        <tr>
                            <td></td>
                            <td>
                                <button class="btn btn-link text-secondary mb-0 bg-warning">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <button class="btn btn-link text-secondary mb-0 bg-warning pl-2">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
//   import SoftBadge from "@/components/SoftBadge.vue";
//   import img1 from "../../../assets/img/team-2.jpg";
//   import img2 from "../../../assets/img/team-3.jpg";
//   import img3 from "../../../assets/img/team-4.jpg";
//   import img4 from "../../../assets/img/team-3.jpg";
//   import img5 from "../../../assets/img/team-2.jpg";
//   import img6 from "../../../assets/img/team-4.jpg";
import authAxios from '../../authAxios';
import moment from 'moment'
  import AddForm from '../components/admin/addService.vue';

export default {
    name: "stock-table",
    components: {
        SoftAvatar,
        //   SoftBadge,
          AddForm,
    },
    data() {
        return {
            // img1,
            // img2,
            // img3,
            // img4,
            // img5,
            // img6,
            service: null,
        };
    },
    created() {
        this.listServices();
    },
    methods: {
        moment,
        async listServices() {
            authAxios.post("list_service").then((resp) => {
                this.service = resp.data.payload
                console.log(this.service, "services")
            }).catch((err) => {
                console.log(err, "services")
            })

        }
    },

};
</script>