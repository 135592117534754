<template>
    <!-- Button trigger modal -->
    <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-warning ml-auto" data-toggle="modal" data-target="#exampleModal">
            Add Client
        </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Client Form</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="addClient">
                        <div class="form-group">
                            <label for="inputAddress2">
                                Choose Branch</label>
                            <select v-model="branch" class="form-control my-1 mr-sm-2">
                                <option>Choose Branch...</option>
                                <option v-for="option in branchList" :key="option.value" :value="option">
                                    {{ option.branch_name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="inputAdfullnamedress">
                                Full Name</label>
                            <input type="text" v-model="fullname" class="form-control" id="fullname"
                                placeholder="full name ">
                        </div>
                        <div class="form-group">
                            <label for="email">
                                Email</label>
                            <input type="email" v-model="email" class="form-control" id="email" placeholder="email ">
                        </div>
                        <div class="form-group">
                            <label for="amount">
                                Amount</label>
                            <input type="number" v-model="amount" class="form-control" id="amount"
                                placeholder="amount ">
                        </div>
                        <div class="form-group">
                            <label for="id_no">
                                ID No</label>
                            <input type="text" v-model="id_no" class="form-control" id="id_no" placeholder="id number">
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Phone Number</label>
                            <input type="tel" v-model="tel" class="form-control" id="inputAddress2"
                                placeholder="id number">
                        </div>

                        <div class="form-group">
                            <label for="inputAddress2">
                                Image</label>
                            <input type="file" class="form-control" ref="image" @change="handleFileUpload" required
                                placeholder="Image">
                        </div>

                        <div class="d-flex justify-content-between mt-4">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-warning">Submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authAxios from '../../../authAxios';
import textArea from '../../../components/SoftTextarea.vue';
export default {
    name: "addStockForm",
    components: {
        textArea,
    },
    data() {
        return {
            branchList: null,
            branch: '',
            amount: '',
            branch: '',
            branch_id: '',
            email: "",
            fullname: "",
            id_no: "",
            tel: "",
            selectedFile: null,
        }
    },
    created() {
        this.listBranch();
    },
    methods: {
        handleFileUpload(event) {
            this.selectedFile = event.target.files[0];
        },
        async listBranch() {
            authAxios.post("list_branch").then((resp) => {
                this.branchList = resp.data.payload
                console.log(this.branchList, "branches form")
            }).catch((err) => {
                console.log(err, "branches")
            })
        },
        async addClient() {

            // console.log(this.branch, 'branch');

            const formData = new FormData();

            formData.append('fullname', this.fullname);
            formData.append('email', this.email);
            formData.append('branch', this.branch.id);
            formData.append('amount', this.amount);
            formData.append('branch_id', this.branch_id);
            formData.append('id_no', this.id_no);
            formData.append('tel', this.tel);
            formData.append('image', this.selectedFile);


            // console.log(formData,'client formData');
            await authAxios
                .post("add_client", formData)
                .then((resp) => {
                    console.log("data", resp);
                    if (resp.data.status === "SUCCESS") {
                        this.fullname = "",
                            this.email = "",
                            this.branch = "",
                            this.amount = "",
                            this.branch_id = "",
                            this.id_no = "",
                            this.tel = "",
                            this.selectedFile = ""
                        // this.toast.success('service added successfully');
                    } else {
                        console.log(resp.data);
                        // this.toast.error(resp.data.status + " something missing");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

        }
    }
}
</script>