<template>
     <div class="card">
          <div class="card-header pb-0">
            <h6>More Details</h6>
            <!-- <AddForm /> -->
        </div>
      <div class="card-body px-0 pb-2" style="height: 740px;">
       
      </div>
    </div>
</template>
<script>
import AddForm from '../../components/admin/settingForms/AddBranch.vue';
export default {
  name: "BranchDetails",
  components: { AddForm },
};
</script>