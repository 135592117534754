/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import '@primevue/themes/aura'
// import 'primevue/resources/themes/saga-blue/theme.css';  // PrimeVue theme
// import 'primevue/resources/primevue.min.css';            // Core PrimeVue CSS
// import 'primeicons/primeicons.css';         
// import ToastPlugin from 'vue-toast-notification';

// import 'vue-toast-notification/dist/theme-sugar.css';

const appInstance = createApp(App);
// appInstance.use(ToastPlugin);
appInstance.use(store);
// appInstance.use(cart);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});
appInstance.mount("#app");
