<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <BranchTable />
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
  import BranchTable from "./components/BranchTable";
  import ProjectsTable from "./components/ProjectsTable";
  
  export default {
    name: "tables",
    components: {
        BranchTable,
   
    },
  };
  </script>