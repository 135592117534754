<template>
    <div class="card mb-4">
        <div class="card-header pb-0">
            <h6>Branch table</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">


                		 			 	
                <table
                    class=" table align-items-center w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                    <thead class="text-xs text-gray-900 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                NAME
                            </th>
                            <th scope="col" class="px-6 py-3">
                                CAPITAL
                            </th>
                            <th scope="col" class="px-6 py-3">
                                WORKING CAPITAL
                            </th>
                            <th scope="col" class="px-6 py-3">
                                SALES
                            </th>
                            <th scope="col" class="px-6 py-3">
                                PROFITS
                            </th>
                            <th scope="col" class="px-6 py-3">
                                VAT
                            </th>
                            <th scope="col" class="px-6 py-3">
                                STARTED
                            </th>
                            <th scope="col" class="px-6 py-3">
                                ADD BY
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="bg-white dark:bg-gray-800" v-for="(items, index) in branchList" :key="index">
                            <th scope="row"
                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ items.branch.branch_name }}
                            </th>
                            <td class="px-6 py-4">
                                {{ items.capital }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.working_capital }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.sales }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.profits }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.vat_amount }}
                            </td>
                            <td class="px-6 py-4">
                                {{ moment(items.created_at).calendar() }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.add_by.name }}
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>
    </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import authAxios from '../../authAxios';
import moment from 'moment'

export default {
    name: "branch-table",
    components: {
        SoftAvatar,
        SoftBadge,
    },
    data() {
        return {
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            branchList: null,
        };
    },
    created() {
        this.listBranch();
    },
    methods: {
        moment,
        async listBranch() {
            authAxios.post("branches").then((resp) => {
                this.branchList = resp.data.payload
                console.log(this.branchList, "branches")
            }).catch((err) => {
                console.log(err, "branches")
            })

        }
    },

};
</script>