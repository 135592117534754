<template>
    <div class="container-fluid">
      <div class="mt-4 page-header min-height-300 border-radius-xl" :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/wave-clolor.jpeg') + ')',
        backgroundPositionY: '50%',
      }">
        <span class="mask bg-gradient-warning opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="h-100 d-flex align-items-center">
                <svg class="text-dark" width="16px" height="16px" viewBox="0 0 40 40" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  
  
                      <title>Capital</title>
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-2020.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
                          <g transform="translate(1716.000000, 291.000000)">
                            <g transform="translate(304.000000, 151.000000)">
                              <polygon class="color-background" opacity="0.596981957"
                                points="18.0883333 15.7316667 11.1783333 8.82166667 13.3333333 6.66666667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 8.82166667 11.1783333 15.315 17.6716667">
                              </polygon>
                              <path class="color-background"
                                d="M31.5666667,23.2333333 C31.0516667,23.2933333 30.53,23.3333333 30,23.3333333 C29.4916667,23.3333333 28.9866667,23.3033333 28.48,23.245 L22.4116667,30.7433333 L29.9416667,38.2733333 C32.2433333,40.575 35.9733333,40.575 38.275,38.2733333 L38.275,38.2733333 C40.5766667,35.9716667 40.5766667,32.2416667 38.275,29.94 L31.5666667,23.2333333 Z"
                                opacity="0.596981957"></path>
                              <path class="color-background"
                                d="M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.2366667,39.1133333 12.6033333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z">
                              </path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
              &nbsp;&nbsp;<h5 class="mb-1">SYSTEM CONFIGURATIONS</h5>
              
            </div>
            <!-- <div class="avatar avatar-xl position-relative"> -->
            <!-- <img
                  src="@/assets/img/bruce-mars.jpg"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                /> -->
            <!-- </div> -->
          </div>
          <div class="col-auto my-auto">
            <!-- <div class="h-100">
                <h5 class="mb-1">HUMAN RESOURCES</h5>
                <p class="mb-0 text-sm font-weight-bold">employee info</p>
              </div> -->
          </div>
          <div class="mx-auto mt-3 col-lg-6 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
            <div class="nav-wrapper position-relative end-0">
              <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
                <li class="nav-item" @click="selectedTab('BranchDetails')">
                  <a class="px-0 py-1 mb-0 nav-link active" data-bs-toggle="tab" href="javascript:;" role="tab"
                    aria-selected="true">
                    <span class="ms-1">Branch Details</span>
                  </a>
                </li>
                <li class="nav-item" @click="selectedTab('Capital')">
                  <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                    aria-selected="false">
                    <span class="ms-1">Capital</span>
                  </a>
                </li>
                <li class="nav-item" @click="selectedTab('EmailSetting')">
                  <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                    aria-selected="false">
                    <span class="ms-1">Email Setting</span>
                  </a>
                </li>
                <li class="nav-item" @click="selectedTab('MoreInfo')">
                  <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                    aria-selected="false">
                    <span class="ms-1">More Setting</span>
                  </a>
                </li>
                <li class="nav-item" @click="selectedTab('othersetting')">
                  <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                    aria-selected="false">
                    <span class="ms-1">Other setting</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid" v-if="activeTab == 'BranchDetails'">
       <BranchDetail/>
    </div>
    <div class="py-4 container-fluid" v-if="activeTab == 'Capital'">
      
        <CapitalDetails/>
     
    </div>
    <div class="py-4 container-fluid" v-if="activeTab == 'EmailSetting'">
       
        <EmailSetting/>
      

    </div>
    <div class="py-4 container-fluid" v-if="activeTab == 'MoreInfo'">
        <MoreSetting/>

    </div>
    <div class="py-4 container-fluid" v-if="activeTab == 'othersetting'">
  <h6>othersetting</h6>

    </div>
  
  </template>
  
  <script>
  import SoftSwitch from "@/components/SoftSwitch.vue";
  import SoftAvatar from "@/components/SoftAvatar.vue";
  import BranchDetail from "../views/Admin/setting/BranchDetails.vue";
  import CapitalDetails from "../views/Admin/setting/CapitalDetails.vue";
  import EmailSetting from "../views/Admin/setting/EmailSetting.vue";
  import MoreSetting from "../views/Admin/setting/MoreSetting.vue";
  import {
    faFacebook,
    faTwitter,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";

  import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import authAxios from "../authAxios.js"
  import moment from 'moment'
  
  export default {
    name: "ProfileOverview",
    components: {
      SoftSwitch,
      BranchDetail,
      CapitalDetails,
      EmailSetting,
      MoreSetting,
      SoftAvatar,

      PlaceHolderCard,
    },
    data() {
      return {
        activeTab: 'BranchDetails',
        employeeList: [],
        branchList: null,
        showMenu: false,
        branch: '',
        branch_id: '',
        email: "",
        fname: "",
        idcard: "",
        lname: "",
        password: "",
        role: "",
        salary: "",
        tel: "",
        faFacebook,
        faTwitter,
        faInstagram,
      };
    },
  
    mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    created() {
      this.listEmployees()
      this.listBranch()
    },
    methods: {
      moment,
      async listBranch() {
        authAxios.post("list_branch").then((resp) => {
          this.branchList = resp.data.payload
          console.log(this.branchList, "branches form")
        }).catch((err) => {
          console.log(err, "branches")
        })
      },
      selectedTab(tab) {
        console.log(tab, 'selectedTab')
        this.activeTab = tab
      },
      async listEmployees() {
        await authAxios.post('list_employees').then((res) => {
          this.employeeList = res.data.payload;
          console.log(res.data, 'employeeList')
        })
      },
       async addEmployee(){
        await authAxios.post('add_employee', {
          branch: this.branch.branch_name,
          branch_id: this.branch.id,
          email: this.email,
          fname: this.fname,
          idcard: this.idcard,
          lname: this.lname,
          password: this.password,
          role: this.role,
          salary: this.salary,
          tel: this.tel,
        }).then((resp) => {
            console.log(resp.data, 'add_employee')
            this.listEmployees()
            // this.$router.go();
          }).catch((err) => {
            console.log(err)
          })
      }
  
  
    }
  };
  </script>