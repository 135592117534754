<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6>RECENT PURCHASE PRODUCTS</h6>
          <!-- <p class="text-sm mb-0">
            <i class="fa fa-check text-info" aria-hidden="true"></i>
            <span class="font-weight-bold ms-1">30 done</span> this month
          </p> -->
        </div>
        <!-- <div class="col-lg-6 col-5 my-auto text-end">
          <div class="dropdown float-lg-end pe-4">
            <a
              class="cursor-pointer"
              id="dropdownTable"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
            </a>
            <ul
              class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
              aria-labelledby="dropdownTable"
            >
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Action</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Another action</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Something else here</a
                >
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
    <div class="card-body px-0 pb-2" style="height: 340px;">
      <div style="max-height: 300px; overflow-y: auto;">
  <table class="table align-items-center mb-0">
    <thead>
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          Item sold
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
          Quantity
        </th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          Amount
        </th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          TOTAL AMOUNT
        </th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          Exp Amount
        </th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          BRANCH
        </th>
      </tr>
    </thead>
    <tbody class="bg-grey-light">
      <tr v-for="(item, index) in recentSales" :key="index" @click="openModal(item)">
        <td>
          <div class="d-flex px-2 py-1">
            <div class="d-flex flex-column justify-content-center">
           
              <h6 class="mb-0 text-sm capitalize">{{ item.Item_sold}}</h6>
            </div>
          </div>
        </td>
        <td>
          <div class="avatar-group mt-2 text-center">
          
            {{ item.Qauntity_sold}} 
          </div>
        </td>
        <td class="align-middle text-center text-sm">
          <span class="text-xs font-weight-bold">N/A</span>
        </td>
        <td class="align-middle">
          <div class="d-flex align-items-center justify-content-center">
            
            <span class="text-xs font-weight-bold mx-2">{{ item.amount_sold}}/=</span>
          </div>
        </td>
        <td class="align-middle">
          <div class="d-flex align-items-center justify-content-center">
           
            <span class="text-xs font-weight-bold mx-2">{{ item.expenditure_amount}}/=</span>
          </div>
        </td>
        <td class="align-middle">
          <div class="d-flex align-items-center justify-content-center">
            <span class="text-xs font-weight-bold mx-2">{{ item.branch.branch_name}}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftProgress from "@/components/SoftProgress.vue";
import img1 from "../../assets/img/small-logos/logo-xd.svg";
import img2 from "../../assets/img/team-1.jpg";
import img3 from "@/assets/img/team-2.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-4.jpg";
import img6 from "../../assets/img/small-logos/logo-atlassian.svg";
import img7 from "../../assets/img/team-2.jpg";
import img8 from "../../assets/img/team-4.jpg";
import img9 from "../../assets/img/small-logos/logo-slack.svg";
import img10 from "../../assets/img/team-3.jpg";
import img11 from "../../assets/img/team-1.jpg";
import img12 from "../../assets/img/small-logos/logo-spotify.svg";
import img13 from "../../assets/img/team-4.jpg";
import img14 from "../../assets/img/team-3.jpg";
import img15 from "../../assets/img/team-4.jpg";
import img16 from "../../assets/img/team-1.jpg";
import img17 from "../../assets/img/small-logos/logo-jira.svg";
import img18 from "../../assets/img/team-4.jpg";
import img19 from "../../assets/img/small-logos/logo-invision.svg";
import img20 from "../../assets/img/team-1.jpg";
import img21 from "../../assets/img/team-4.jpg";
import authAxios from '../../authAxios.js';

export default {
  name: "projects-card",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      img12,
      img13,
      img14,
      img15,
      img16,
      img17,
      img18,
      img19,
      img20,
      img21,
      recentSales: null,
    };
  },
  components: {
    SoftAvatar,
    SoftProgress,
  },
  mounted() {
    setTooltip();
  },
  created() {
    this.listRecentSales();
  },
  methods: {
    async listRecentSales() {

      await authAxios
        .post("recent_sale")
        .then((resp) => {

          this.recentSales = resp.data.payload;
          console.log(this.recentSales, 'recent_sale');

        }).catch((err) => {
          console.log(err)
        })
        .finally(() => {
          console.log("cant fetch recent_sale");
        });

    }
  }
};
</script>
