<template>
    <div>
      <!-- Button trigger modal -->
    
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Add Project
      </button>
    </div>
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Project Form</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addProject">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label for="inputAddress2">Project Name</label>
                    <input type="text" v-model="name" class="form-control" id="inputAddress2" placeholder="Project Name">
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="budget_amount">Budget amount</label>
                    <input type="number" v-model="budget_amount" class="form-control" id="budget_amount" placeholder="1000,0000">
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="paid_amount">Paid amount</label>
                    <input type="number" v-model="paid_amount" class="form-control" id="paid_amount" placeholder="1000,0000">
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="start_date">Start date</label>
                    <input type="date" v-model="start_date" class="form-control" id="start_date" placeholder="2022-01-01">
                  </div>
                </div>
  
                <div class="row">
                  <div class="form-group col-md-3">
                    <label for="end_date">End date</label>
                    <input type="date" v-model="end_date" class="form-control" id="end_date" placeholder="2022-01-01">
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="deadline">Deadline</label>
                    <input type="date" v-model="deadline" class="form-control" id="deadline" placeholder="2022-01-01">
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="service_id">Under service</label>
                    <select class="form-select" v-model="service_id">
                      <option value="">Choose Service...</option>
                      <option v-for="service in serviceList" :key="service.id" :value="service.id">
                        {{ service.name }}
                      </option>
                    </select>
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="status">Status</label>
                    <!-- <input type="text" v-model="status" class="form-control" id="status" placeholder="Select status"> -->
                    <select class="form-select" v-model="status">
                      <option value="">Choose Status...</option>
                     
                      <option value="pending">pending</option>
                      <option value="in_progress">progress</option>
                      <option value="completed">completed</option>
                       
                    </select>
                  </div>
                </div>
  
                <div class="row">
                 
  
                  <div class="form-group col-md-3">
                    <label for="clients">Client</label>
                    <select class="form-select" v-model="clients">
                      <option value="">Choose Client...</option>
                      <option v-for="client in clientsList" :key="client.id" :value="client.id">
                        {{ client.fullname }}
                      </option>
                    </select>
                  </div>
  
                  
  
                  <div class="form-group col-md-3">
                    <label for="team_lead">Team Lead</label>
                    <input type="text" v-model="team_lead" class="form-control" id="team_lead" placeholder="Team Lead">
                  </div>
                  <div class="form-group col-md-3">
                 
                   
                   
                        <label for="clients">Engineers</label>
                    <select class="form-select" v-model="selectedEngineers">
                      <option value="">Choose Engineers...</option>
                      <option v-for="engineer in engineersList" :key="engineer.id" :value="engineer.id">
                        {{  engineer.name  }}
                      </option>
                    </select>
                    </div>
            
                </div>
  
                <div class="row">
                  <div class="form-group col-md-3">
                    <label for="estimated_duration">Estimated Duration (Days)</label>
                    <input type="number" v-model="estimated_duration" class="form-control" id="estimated_duration" placeholder="Duration in days">
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="location">Location</label>
                    <input type="text" v-model="location" class="form-control" id="location" placeholder="Project Location">
                  </div>
  
                  <div class="form-group col-md-3">
                    <label for="project_image">Project Image</label>
                    <input type="file" class="form-control" ref="project_image" @change="handleFileUpload" required>
                  </div>
                
                  <div class="form-group col-md-3">
                    <label for="percentage">Percentage</label>
                  

                    <Knob v-model="percentage" valueTemplate="{value}%" />
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea v-model="description" class="form-control" id="description" rows="3"></textarea>
                </div>
  
                <div class="d-flex justify-content-between mt-4">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-warning">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { postData } from '../../../utils/apiUtils';
  import MultiSelect from '@/components/MultiSelect.vue'; 
  import Knob from 'primevue/knob';// Adjust the path if necessary
  
  export default {
    name: "AddProject",
    components: {
      MultiSelect,
      Knob
    },
    data() {
      return {
        engineersList: [],
        clientsList: [],
        serviceList: [],
        selectedEngineers: [],
  
        name: '',
        budget_amount: 0,
        paid_amount: 0,
        start_date: '',
        end_date: '',
        deadline: '',
        service_id: '',
        status: '',
        percentage: 0,
        clients: '',
        description: '',
        project_image: null,
        team_lead: '',
        estimated_duration: 0,
        location: '',
      }
    },
    created() {
      this.getEngineers();
      this.getClients();
      this.getServices();
    },
    methods: {
      handleFileUpload(event) {
        this.project_image = this.$refs.project_image.files[0];
      },
      async addProject() {
        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('budget_amount', this.budget_amount);
        formData.append('paid_amount', this.paid_amount);
        formData.append('start_date', this.start_date);
        formData.append('end_date', this.end_date);
        formData.append('deadline', this.deadline);
        formData.append('service_id', this.service_id);
        formData.append('status', this.status);
        formData.append('percentage', this.percentage);
        formData.append('clients', this.clients);
        // formData.append('engineers', JSON.stringify(this.selectedEngineers)); // Convert array to JSON
        // formData.append(`clients[]`, [this.clients]);
     
        formData.append('engineers', this.selectedEngineers);
        formData.append('description', this.description);
        formData.append('team_lead', this.team_lead);
        formData.append('estimated_duration', this.estimated_duration);
        formData.append('location', this.location);
        formData.append('project_image', this.project_image);
  
        try {
          const result = await postData('project', formData); // Replace 'project' with your API endpoint
          alert('Project successfully added!');
        } catch (error) {
          console.log('Error adding project:', error);
        }
      },
  
      async getEngineers() {
        try {
          const result = await postData('list_engineers');
          this.engineersList = result.payload;
        } catch (error) {
          console.log('Error fetching engineers:', error);
        }
      },
      async getClients() {
        try {
          const result = await postData('list_client');
          this.clientsList = result.payload;
        } catch (error) {
          console.log('Error fetching clients:', error);
        }
      },
      async getServices() {
        try {
          const result = await postData('list_service');
          this.serviceList = result.payload;
        } catch (error) {
          console.log('Error fetching services:', error);
        }
      },
    }
  }
  </script>
  
  <!-- Include PrimeVue CSS in your global styles or main file -->
  