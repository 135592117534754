import authAxios from "../authAxios";

// GET request
export const fetchData = async (endpoint) => {
    try {
      const response = await authAxios.post(endpoint);
      return response.data;
    } catch (error) {
      console.error('API GET error:', error);
      throw error;
    }
  };

  // POST request
export const postData = async (endpoint, data) => {
  try {
    const response = await authAxios.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('API POST error:', error);
    throw error;
  }
};