<template>
    <div class="card mb-4" style="height: 520px;">
      <div class="card-header pb-0">
        <h6>Sales table</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                ITEM SOLD
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                QAUNTITY
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                AMOUNT
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                EXP
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                EXP AMNT
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                TOTAL PRICE
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                BRANCH
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                DATE
                </th>
                <th class="text-secondary opacity-7">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr  v-for="(Sale, index) in Sales" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <!-- <div> -->
                      <!-- <soft-avatar
                        :img="img1"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="user1"
                      />
                    </div> -->
                    <div class="d-flex flex-column justify-content-center">
                        <!-- {{ listSales }} -->
                      <h6 class="mb-0 text-sm">{{ Sale.Item_sold }}</h6>
                      <!-- <p class="text-xs text-secondary mb-0">
                        john@creative-tim.com
                      </p> -->
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ Sale.Qauntity_sold }}</p>
                  <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
                </td>
                
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ Sale.amount_sold }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ Sale.expenditure }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ Sale.expenditure_amount }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ Sale.totalprice }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ Sale.branch.branch_name }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >{{ moment(Sale.created_at).calendar() }}</span
                  >
                </td>
                <td class="align-middle">
                    <td class="align-middle text-center text-sm">
                  <soft-badge color="warning" variant="gradient" size="sm"
                    >EDIT</soft-badge
                  >
                </td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SoftAvatar from "@/components/SoftAvatar.vue";
  import SoftBadge from "@/components/SoftBadge.vue";
  import img1 from "../../assets/img/team-2.jpg";
  import img2 from "../../assets/img/team-3.jpg";
  import img3 from "../../assets/img/team-4.jpg";
  import img4 from "../../assets/img/team-3.jpg";
  import img5 from "../../assets/img/team-2.jpg";
  import img6 from "../../assets/img/team-4.jpg";
  import authAxios from '../../authAxios';
  import moment from 'moment'
  
  export default {
    name: "sale-table",
    components: {
      SoftAvatar,
      SoftBadge,
    },
    data() {
      return {
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        Sales: null,
      };
    },
    created() {
        this.listSales();
    },
    methods: {
        moment,
        async listSales() {
            authAxios.post("list_sale").then((resp) => {
                this.Sales = resp.data.payload
                console.log(this.Sales,"list_sale")
            }).catch((err) => {
                console.log(err,"list_sale")
            })

        }
    },
    
  };
  </script>
  