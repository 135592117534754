<template>
    <div class="multi-select">
      <input
        type="text"
        v-model="searchTerm"
        @focus="isOpen = true"
        @blur="closeDropdown"
        :placeholder="placeholder"
        class="form-control"
      />
      <div v-if="isOpen" class="dropdown-menu show">
        <ul class="list-unstyled mb-0">
          <li
            v-for="option in filteredOptions"
            :key="option.value"
            @click="toggleOption(option)"
            :class="['dropdown-item', { active: isSelected(option) }]"
          >
            {{ option.name }}
          </li>
        </ul>
      </div>
      <div v-if="selectedOptions.length" class="selected">
        <span v-for="option in selectedOptions" :key="option.value" class="badge bg-primary me-1">
          {{ option.name }}
        </span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        required: true
      },
      value: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: 'Select'
      },
      maxSelectedLabels: {
        type: Number,
        default: 3
      }
    },
    data() {
      return {
        isOpen: false,
        searchTerm: ''
      };
    },
    computed: {
      filteredOptions() {
        return this.options.filter(option =>
          option.label.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      },
      selectedOptions() {
        return this.options.filter(option =>
          this.value.includes(option.value)
        );
      }
    },
    methods: {
      toggleOption(option) {
        const index = this.value.indexOf(option.value);
        if (index > -1) {
          this.value.splice(index, 1);
        } else {
          this.value.push(option.value);
        }
        this.$emit('input', this.value);
      },
      isSelected(option) {
        return this.value.includes(option.value);
      },
      closeDropdown() {
        setTimeout(() => {
          this.isOpen = false;
        }, 200);
      }
    }
  };
  </script>
  
  <style scoped>
  .multi-select {
    position: relative;
    width: 100%;
  }
  .dropdown-menu {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    background: white;
    z-index: 1000;
  }
  .dropdown-item.active {
    background: #007bff;
    color: white;
  }
  .selected {
    display: flex;
    flex-wrap: wrap;
  }
  </style>
  