<template>
    <div class="card mb-4">
        <div class="card-header pb-0">
            <h6>Stock table</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">



                <table
                    class=" table align-items-center w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                    <thead class="text-xs text-gray-900 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Product name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                QTY
                            </th>
                            <th scope="col" class="px-6 py-3">
                                STOCK AMNT
                            </th>
                            <th scope="col" class="px-6 py-3">
                                STOCK PRICE
                            </th>
                            <th scope="col" class="px-6 py-3">
                                SALE PRICE
                            </th>
                            <th scope="col" class="px-6 py-3">
                                PROFITS
                            </th>
                            <th scope="col" class="px-6 py-3">
                                SHELL
                            </th>
                            <th scope="col" class="px-6 py-3">
                                LEFT QTY
                            </th>
                            <th scope="col" class="px-6 py-3">
                                ACTION
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="bg-white dark:bg-gray-800" v-for="(items, index) in Stocks" :key="index">
                            <th scope="row"
                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ items.itemstocked }}
                            </th>
                            <td class="px-6 py-4">
                                {{ items.stockquantity }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.stockamount }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.stockprice }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.saleprice }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.profits }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.shell }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.warn_qty }}
                            </td>
                            <td class="align-middle justify-content-between">
                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <i class="pi pi-cart-plus" @click="addToCart(items)" style="font-size: 2rem"></i>
                                <!-- <button class="btn btn-link text-secondary mb-0 bg-warning">
                                   33 <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button> -->
                            </td>
                            <!-- <td></td> -->
                            <!-- <td></td> -->
                            <!-- <td>
                                <button class="btn btn-link text-secondary mb-0 bg-warning pl-2">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td> -->
                        </tr>
                        </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>
    </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import authAxios from '../../authAxios';
import 'primeicons/primeicons.css';
import { mapActions} from 'vuex';
import moment from 'moment'

export default {
    name: "sale-table",
    components: {
        SoftAvatar,
        SoftBadge,
    },
    data() {
        return {
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            Stocks: null,
        };
    },
    created() {
        this.listStocks();
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        moment,
        async listStocks() {
            authAxios.post("list_stock").then((resp) => {
                this.Stocks = resp.data.payload
                console.log(this.Stocks, "stockList")
            }).catch((err) => {
                console.log(err, "stockList")
            })

        }
    },

};
</script>