<template>
     <div class="card">
          <div class="card-header pb-0">
            <h6>Branch Details</h6>
            <AddForm />
        </div>
      <div class="card-body px-0 pb-2" style="height: 740px;">
        <div style="max-height: 700px; overflow-y: auto;">
         
          <table class="table align-items-center mb-0">

            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Branch Name
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
               Created Date
                </th>
                <th class="text-secondary opacity-7">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(branch, index) in branchList" :key="index">
                <td class="px-4">
                  <div class="d-flex px-1 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ branch.branch_name }}</h6>
                     
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">  {{ moment(branch.created_at).calendar() }}</p>
                </td>
                <td class="align-middle">
                <td class="align-middle text-center text-sm">
                  <soft-badge color="warning" variant="gradient" size="sm">EDIT</soft-badge>
                </td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>
<script>
import AddForm from '../../components/admin/settingForms/AddBranch.vue';
import authAxios from "../../../authAxios";
import moment from 'moment'
export default {
  name: "BranchDetails",
  components: { AddForm },

  data() {    
    return {
     branchList: null,

    };
  },
  created() {
     this.listBranch()
  },
  methods: {
    moment,
     async listBranch() {
            authAxios.post("list_branch").then((resp) => {
                this.branchList = resp.data.payload
                console.log(this.branchList, "branches form")
            }).catch((err) => {
                console.log(err, "branches")
            })
        },
  },
};
</script>