<template>
    <!-- Button trigger modal -->
    <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-warning ml-auto" data-toggle="modal" data-target="#exampleModal">
            Add Stock
        </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Stock Form</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="addStock">

                        <div class="form-group">
                            <label for="inputAddress2">
                                Choose Branch</label>
                            <select v-model="branch" class="form-control my-1 mr-sm-2">
                                <option>Choose Branch...</option>
                                <option v-for="option in branchList" :key="option.value" :value="option">
                                    {{ option.branch_name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="inputAddress2">
                                Item stocked</label>
                            <input type="text" v-model="itemstocked" class="form-control" id="inputAddress2"
                                placeholder="Item stock ">
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Stock quantity</label>
                            <input type="text" v-model="stockquantity" class="form-control" id="inputAddress2"
                                placeholder="Stock quantity">
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Stock price</label>
                            <input type="text" v-model="stockprice" class="form-control" id="inputAddress2"
                                placeholder="Stock price">
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Sale price</label>
                            <input type="text" v-model="saleprice" class="form-control" id="inputAddress2"
                                placeholder="Sale price">
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Shell</label>
                            <input type="text" v-model="shell" class="form-control" id="inputAddress2"
                                placeholder="Shell">
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Warn qty</label>
                            <input type="text" v-model="warn_qty" class="form-control" id="inputAddress2"
                                placeholder="Warn qty">
                        </div>

                        <div class="d-flex justify-content-between mt-4">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-warning">Submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authAxios from '../../../authAxios';
export default {
    name: "addStockForm",
    // props: ["branch_id"],
    data() {
        return {
            branchList: null,
            branch: '',
            itemstocked: '',
            stockquantity: '',
            stockprice: '',
            saleprice: '',
            shell: '',
            warn_qty: '',
        }
    },
    created() {
        this.listBranch()
    },
    methods: {
        // setBranchId(id) {
        //     this.branch_id = id
        //     console.log(this.branch_id, 'branch_id')
        // },
        async listBranch() {
            authAxios.post("list_branch").then((resp) => {
                this.branchList = resp.data.payload
                console.log(this.branchList, "branches form")
            }).catch((err) => {
                console.log(err, "branches")
            })
        },
        async addStock() {
      
            await authAxios.post('add_stock', {
                branch: this.branch.branch_name,
                branch_id: this.branch.id,
                itemstocked: this.itemstocked,
                stockquantity: this.stockquantity,
                stockprice: this.stockprice,
                saleprice: this.saleprice,
                shell: this.shell,
                warn_qty: this.warn_qty,
            }).then((resp) => {
                    console.log(resp.data, 'add_stock')
                    // this.$router.go();
                }).catch((err) => {
                    console.log(err)
                })
        }
    }
}
</script>