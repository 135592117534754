<template>
    <div class="card mb-4" style="height: 520px;">

        <div class="card-header pb-0">
            <h6>Clients Details</h6>
            <AddForm />
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
                <table
                    class=" table align-items-center w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    		
                    <thead class="text-xs text-gray-900 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                FULL NAME
                            </th>
                            <th scope="col" class="px-6 py-3">
                                EMAIL
                            </th>
                            <th scope="col" class="px-6 py-3">
                                TEL
                            </th>
                            <th scope="col" class="px-6 py-3">
                                ID-NO
                            </th>
                            <th scope="col" class="px-6 py-3">
                                PAID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                BAL
                            </th>
                            <th scope="col" class="px-6 py-3">
                                BRANCH
                            </th>
                            <th scope="col" class="px-6 py-3">
                              JOIN  DATE
                            </th>
                           
                           <th></th>
                           <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="bg-white dark:bg-gray-800" v-for="(items, index) in ClientDetails" :key="index">
                           
                            <td>
                                <div class="d-flex px-1 py-1">
                                    <div>
                                        <soft-avatar class="me-2" :img="img2" alt="kal" border-radius="lg"
                                            shadow="regular" />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">
                                            {{ items.fullname }}
                                        </h6>
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                              
                                {{ items.email }}
                            </td>
                            <td class="px-6 py-4">
                              
                                {{ items.tel }}
                            </td>
                            <td class="px-6 py-4">
                              
                                {{ items.id_no }}
                            </td>
                            <td class="px-6 py-4">
                              
                                {{ items.amount }}/=
                            </td>
                            <td class="px-6 py-4">
                              
                               0.00/=
                            </td>
                            <td class="px-6 py-4">
                              
                                {{ items && items.branch ? items.branch.branch_name : '' }}
                            </td>
                            <td class="px-6 py-4">
                              
                                {{ moment(items.created_at).calendar()}}
                            </td>
                            <td class="align-middle justify-content-between">
                        <tr>
                            <td></td>
                            <td>
                                <button class="btn btn-link text-secondary mb-0 bg-warning">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <button class="btn btn-link text-secondary mb-0 bg-warning pl-2">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import img2 from "../../assets/img/team-3.jpg";
import authAxios from '../../authAxios';
import moment from 'moment'
import AddForm from '../components/admin/addClient.vue';

export default {
    name: "stock-table",
    components: {
        SoftAvatar,
          AddForm,
    },
    data() {
        return {
            img2,
            ClientDetails: null,
        };
    },
    created() {
        this.listClient();
    },
    methods: {
        moment,
        async listClient() {
            authAxios.post("list_client").then((resp) => {
                this.ClientDetails = resp.data.payload
                console.log(this.ClientDetails, "ClientDetails")
            }).catch((err) => {
                console.log(err, "ClientDetails")
            })

        }
    },

};
</script>