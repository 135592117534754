import axios from 'axios';

// Create an instance of axios with default settings
const axiosInstance = axios.create({
  baseURL: 'https://syt.yolumsltd.com/api/', // Set your base URL here
  // baseURL: 'http://127.0.0.1:8001/api/', // Set your base URL here
  timeout: 6000,                      // Set a timeout if needed
  headers: { 'X-Custom-Header': 'foobar' }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(config => {
  // Modify config before sending request (e.g., add auth token)
  return config;
}, error => {
  // Handle request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(response => {
  // Handle response data
  return response;
}, error => {
  // Handle response error
  return Promise.reject(error);
});

export default axiosInstance;
