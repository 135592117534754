<template>
  <div class="py-4 container-fluid">
      <div class="row">
          <div class="col-12">
              <StockTable />
          </div>
      </div>
      
  </div>
</template>

<script>
import StockTable from "./components/StockTable";

export default {
  name: "tables",
  components: {
    StockTable,
 
  },
};
</script>