<template>
    <!-- Button trigger modal -->
    <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-warning ml-auto" data-toggle="modal" data-target="#exampleModal">
            Add Engineer
        </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Engineer Form</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="addEngineer">

                        <div class="form-group">
                            <label for="inputAddress2">
                                Engineer</label>
                            <select class="form-control" v-model="engineer">
                                <option>Choose Engineer...</option>
                                <option v-for="option in employeeList" :key="option.value" :value="option"> {{
                                    option.fname }}&nbsp;{{ option.lname }}</option>
                            </select>
                            
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Specialization</label>

                            <input type="text" v-model="specialization" class="form-control" id="inputAddress2"
                                placeholder="specialization">
                        </div>

                        <div class="d-flex justify-content-between mt-4">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-warning">Submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authAxios from '../../../authAxios';
export default {
    name: "addEngineerForm",
    // props: ["branch_id"],
    data() {
        return {
            branchList: null,
            branch: '',
            engineer: '',
            specialization: '',
            employeeList: [],
        }
    },
    created() {
        this.listEmployees()
    },
    methods: {

        async listEmployees() {
            await authAxios.post('list_employees').then((res) => {
                this.employeeList = res.data.payload;
                console.log(res.data, 'employeeList')
            })
        },
        async addEngineer() {
            // console.log(this.engineer.fname +' '+ this.engineer.lname, 'engineer')
            await authAxios.post('add_engineers', {
                "name": this.engineer.fname +' '+ this.engineer.lname,
                "specialization": this.specialization,
                "employee_id":2
            }).then((resp) => {
                console.log(resp.data, 'add_Engineer')
                $('#exampleModal').modal('hide');
                // this.$router.go();
            }).catch((err) => {
                console.log(err)
            })
        }
    }
}
</script>