import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import TodaySales from "@/views/TodaySales.vue";
import Projects from "@/views/Projects.vue";
import StockList from "@/views/stockList.vue";
import AdminStockList from "@/views/Admin/Stock.vue";
import servicePage from "@/views/Admin/servicePage.vue";
import settingPage from "@/views/settingPage.vue";
import clientPage from "@/views/Admin/clientPage.vue";
import EmployeeInfo from "@/views/Admin/hrmPage.vue";
import BranchList from "@/views/BranchList.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";

// import Swal from 'sweetalert2';

const checkUserType = (to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));

  console.log(user, 'user danda')
  if (user.level == 'CONTROL') {
   
    next();
 
  } else {

    console.log('Not ok');
    next('/sign-in');
  }
}

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",

  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: checkUserType,
  },
  {
    path: "/sales",
    name: "Sales",
    component: TodaySales,
    beforeEnter: checkUserType,
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    beforeEnter: checkUserType,
  },
  {
    path: "/stocklist",
    name: "StockList",
    component: StockList,
    beforeEnter: checkUserType,
  },
  {
    path: "/stock",
    name: "stock",
    component: AdminStockList,
    beforeEnter: checkUserType,
  },
  {
    path: "/employee-info",
    name: "employee-info",
    component: EmployeeInfo,
    beforeEnter: checkUserType,
  },
  {
    path: "/services",
    name: "services",
    component: servicePage,
    beforeEnter: checkUserType,
  },
  {
    path: "/clients",
    name: "clients",
    component: clientPage,
    beforeEnter: checkUserType,
  },
  {
    path: "/settings",
    name: "settings",
    component: settingPage,
    beforeEnter: checkUserType,
  },
  {
    path: "/branches",
    name: "Branches",
    component: BranchList,
    beforeEnter: checkUserType,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: checkUserType,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});



export default router;
