<template>
  <div class="container-fluid">
    <div class="mt-4 page-header min-height-300 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/wave-clolor.jpeg') + ')',
      backgroundPositionY: '50%',
    }">
      <span class="mask bg-gradient-warning opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="h-100">
            <h5 class="mb-1">HUMAN RESOURCES</h5>
            <p class="mb-0 text-sm font-weight-bold">employee info</p>
          </div>
          <!-- <div class="avatar avatar-xl position-relative"> -->
          <!-- <img
                src="@/assets/img/bruce-mars.jpg"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              /> -->
          <!-- </div> -->
        </div>
        <div class="col-auto my-auto">
          <!-- <div class="h-100">
              <h5 class="mb-1">HUMAN RESOURCES</h5>
              <p class="mb-0 text-sm font-weight-bold">employee info</p>
            </div> -->
        </div>
        <div class="mx-auto mt-3 col-lg-6 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div class="nav-wrapper position-relative end-0">
            <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
              <li class="nav-item" @click="selectedTab('EMPLOYES')">
                <a class="px-0 py-1 mb-0 nav-link active" data-bs-toggle="tab" href="javascript:;" role="tab"
                  aria-selected="true">
                  <svg class="text-dark" width="16px" height="16px" viewBox="0 0 42 42" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z">
                            </path>
                            <path class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"></path>
                            <path class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>

                  <span class="ms-1">EMPLOYES</span>
                </a>
              </li>
              <li class="nav-item" @click="selectedTab('AddEMPLOYES')">
                <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                  aria-selected="false">
                  <svg class="text-dark" width="16px" height="16px" viewBox="0 0 40 44" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>document</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"></path>
                            <path class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>

                  <span class="ms-1">Add Employee</span>
                </a>
              </li>
              <li class="nav-item" @click="selectedTab('EngineerSection')">
                <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                  aria-selected="false">
                  <svg class="text-dark" width="16px" height="16px" viewBox="0 0 40 40" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">


                    <title>Engineers</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-2020.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(304.000000, 151.000000)">
                            <polygon class="color-background" opacity="0.596981957"
                              points="18.0883333 15.7316667 11.1783333 8.82166667 13.3333333 6.66666667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 8.82166667 11.1783333 15.315 17.6716667">
                            </polygon>
                            <path class="color-background"
                              d="M31.5666667,23.2333333 C31.0516667,23.2933333 30.53,23.3333333 30,23.3333333 C29.4916667,23.3333333 28.9866667,23.3033333 28.48,23.245 L22.4116667,30.7433333 L29.9416667,38.2733333 C32.2433333,40.575 35.9733333,40.575 38.275,38.2733333 L38.275,38.2733333 C40.5766667,35.9716667 40.5766667,32.2416667 38.275,29.94 L31.5666667,23.2333333 Z"
                              opacity="0.596981957"></path>
                            <path class="color-background"
                              d="M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.2366667,39.1133333 12.6033333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Engineers</span>
                </a>
              </li>
              <li class="nav-item" @click="selectedTab('MoreInfo')">
                <a class="px-0 py-1 mb-0 nav-link" data-bs-toggle="tab" href="javascript:;" role="tab"
                  aria-selected="false">
                  <svg class="text-dark" width="16px" height="16px" viewBox="0 0 40 40" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">


                    <title>Contacts</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-2020.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(304.000000, 151.000000)">
                            <polygon class="color-background" opacity="0.596981957"
                              points="18.0883333 15.7316667 11.1783333 8.82166667 13.3333333 6.66666667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 8.82166667 11.1783333 15.315 17.6716667">
                            </polygon>
                            <path class="color-background"
                              d="M31.5666667,23.2333333 C31.0516667,23.2933333 30.53,23.3333333 30,23.3333333 C29.4916667,23.3333333 28.9866667,23.3033333 28.48,23.245 L22.4116667,30.7433333 L29.9416667,38.2733333 C32.2433333,40.575 35.9733333,40.575 38.275,38.2733333 L38.275,38.2733333 C40.5766667,35.9716667 40.5766667,32.2416667 38.275,29.94 L31.5666667,23.2333333 Z"
                              opacity="0.596981957"></path>
                            <path class="color-background"
                              d="M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.2366667,39.1133333 12.6033333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Contacts</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid" v-if="activeTab == 'EMPLOYES'">

    <div class="card">
      <div class="card-body px-0 pb-2" style="height: 740px;">
        <div style="max-height: 700px; overflow-y: auto;">
          <table class="table align-items-center mb-0">

            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Name
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Email
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Employee ID
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Phone
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Join Date
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Role
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Salary
                </th>
                <th class="text-secondary opacity-7">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(employee, index) in employeeList" :key="index">
                <td>
                  <div class="d-flex px-1 py-1">
                    <div>
                      <soft-avatar class="me-2" :img="sophie" alt="kal" border-radius="lg" shadow="regular" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ employee.fname }}&nbsp;{{ employee.lname }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ employee.email }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0"> {{ employee.email }}</p>
                  <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
                </td>

                <td class="align-middle text-center">
                  <!-- idcard -->
                  <span class="text-secondary text-xs font-weight-bold"> {{ employee.idcard }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ employee.tel }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ moment(employee.created_at).calendar()
                    }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ employee.role === 2 ? 'Staff' : 'Super admin'
                    }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ employee.salary }}</span>
                </td>

                <td class="align-middle">
                <td class="align-middle text-center text-sm">
                  <soft-badge color="warning" variant="gradient" size="sm">EDIT</soft-badge>
                </td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid" v-if="activeTab == 'AddEMPLOYES'">
    <form @submit.prevent="addEmployee">
      <div class="mt-3 row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">Basic Info</h6>
            </div>
            <div class="p-3 card-body">


              <div class="form-group">
                <label for="inputAddress2">
                  First Name</label>
                <input type="text" v-model="fname" class="form-control" id="inputAddress2" placeholder="First Name ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Last Name</label>
                <input type="text" v-model="lname" class="form-control" id="inputAddress2" placeholder="Last Name ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Salary</label>
                <input type="text" v-model="salary" class="form-control" id="inputAddress2" placeholder=" salary ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Email address</label>
                <input type="email" v-model="email" class="form-control" id="inputAddress2" placeholder=" email ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Password</label>
                <input type="password" v-model="password" class="form-control" id="inputAddress2"
                  placeholder="Password">
              </div>


            </div>
          </div>
        </div>

        <div class="mt-4 col-12 col-xl-4 mt-xl-0">
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">More Info</h6>
            </div>
            <div class="p-3 card-body">

              <div class="form-group">
                <label for="inputAddress2">
                  Employee ID</label>
                <input type="text" v-model="idcard" class="form-control" id="inputAddress2" placeholder="Id card ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Phone</label>
                <input type="tel" v-model="tel" class="form-control" id="inputAddress2" placeholder="Phone number ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Choose Role</label>
                <select v-model="role" class="form-control my-1 mr-sm-2">
                  <option>Choose Role...</option>
                  <option value="2">
                    Staff
                  </option>
                  <option value="1">
                    Super admin
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Profile Pic</label>
                <input type="file" class="form-control" id="inputAddress2" placeholder="Profile">
              </div>

            </div>
          </div>
        </div>
        <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
          <!-- Address -->
          <div class="card h-100">
            <div class="p-3 pb-0 card-header">
              <h6 class="mb-0">Address Info</h6>
            </div>
            <div class="p-3 card-body">


              <div class="form-group">
                <label for="inputAddress2">
                  Address 1</label>
                <input type="text" v-model="address" class="form-control" id="inputAddress2" placeholder="Address ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  City</label>
                <input type="text" v-model="city" class="form-control" id="inputAddress2" placeholder="city ">
              </div>
              <div class="form-group">
                <label for="inputAddress2">
                  Choose Branch</label>
                <select v-model="branch" class="form-control my-1 mr-sm-2">
                  <option>Choose Branch...</option>
                  <option v-for="option in branchList" :key="option.value" :value="option">
                    {{ option.branch_name }}
                  </option>
                </select>
              </div>


            </div>
          </div>

        </div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button type="submit" class="btn btn-warning">Submit</button>
      </div>

    </form>

  </div>
  <div class="py-4 container-fluid" v-if="activeTab == 'EngineerSection'">
    <div class="card px-3">
     <div class="card-header pb-0">
      <addEngineers />
     </div>
      <div class="card-body px-0 pb-2" style="height: 740px;">
        <div style="max-height: 700px; overflow-y: auto;">
          <table class="table align-items-center mb-0">

            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Name
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  specialization
                </th>
    
                <th class="text-secondary opacity-7">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(engineer, index) in egineerList" :key="index">
                <td>
                  <div class="d-flex px-1 py-1">
                    <!-- <div>
                      <soft-avatar class="me-2" :img="sophie" alt="kal" border-radius="lg" shadow="regular" />
                    </div> -->
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ engineer.name }}</h6>
                      <!-- <p class="text-xs text-secondary mb-0">
                        {{ engineer.specialization }}
                      </p> -->
                    </div>
                  </div>
                </td>
                <td class="align-middle"> {{ engineer.specialization }}</td>
                <td class="align-middle">
                <td class="align-middle text-center text-sm">
                  <soft-badge color="warning" variant="gradient" size="sm">EDIT</soft-badge>
                </td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import addEngineers from "../Admin/AdminForm/addEngineers.vue";
//   import ProfileInfoCard from "../components/ProfileInfoCard.vue";
import ProfileInfoCard from "../components/ProfileInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import DefaultProjectCard from "../components/DefaultProjectCard.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import authAxios from "../../authAxios.js"
import moment from 'moment'


export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    SoftAvatar,
    DefaultProjectCard,
    PlaceHolderCard,
    addEngineers,
  },
  data() {
    return {
      activeTab: 'EMPLOYES',
      employeeList: [],
      egineerList: [],
      branchList: null,
      showMenu: false,
      branch: '',
      branch_id: '',
      email: "",
      fname: "",
      idcard: "",
      lname: "",
      password: "",
      role: "",
      salary: "",
      tel: "",
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
    };
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  created() {
    this.listEmployees()
    this.listBranch()
    this.listEngineer()
  },
  methods: {
    moment,
    async listBranch() {
      authAxios.post("list_branch").then((resp) => {
        this.branchList = resp.data.payload
        console.log(this.branchList, "branches form")
      }).catch((err) => {
        console.log(err, "branches")
      })
    },
    selectedTab(tab) {
      console.log(tab, 'selectedTab')
      this.activeTab = tab
    },
    async listEmployees() {
      await authAxios.post('list_employees').then((res) => {
        this.employeeList = res.data.payload;
        console.log(res.data, 'employeeList')
      })
    },
    async listEngineer() {
      await authAxios.post('list_engineers').then((res) => {
        this.egineerList = res.data.payload;
        console.log(res.data, 'egineerList')
      })
    },
     async addEmployee(){
      await authAxios.post('add_employee', {
        branch: this.branch.branch_name,
        branch_id: this.branch.id,
        email: this.email,
        fname: this.fname,
        idcard: this.idcard,
        lname: this.lname,
        password: this.password,
        role: this.role,
        salary: this.salary,
        tel: this.tel,
      }).then((resp) => {
          console.log(resp.data, 'add_employee')
          this.listEmployees()
          // this.$router.go();
        }).catch((err) => {
          console.log(err)
        })
    }


  }
};
</script>