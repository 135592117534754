<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <!-- <link rel="icon" type="image/png" href="./src/assets/img/logos/yolums.jpg" /> -->

    <!-- <div class="row">
      <div class="col-12">
        <h6 class="py-4">YOLUMS LTD</h6> -->
    <!-- <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        /> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-warning text-gradient">
                    <img src="@/assets/img/logos/yolums.jpg" class="mb-2 rounded-circle" alt="main_logo">
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="handleSignIn">
                    <label>Email</label>

                    <input type="email" v-model="email" class="form-control mb-3" placeholder="Email" required="" />
                    <label>Password</label>
                    <!-- <soft-input id="password" type="password" placeholder="Password" v-model="password" /> -->
                    <input type="password" v-model="password" class="form-control mb-3" placeholder="Password"
                      required="" />
                    <!-- <soft-switch id="rememberMe" name="rememberMe" checked>
                      Remember me
                    </soft-switch> -->
                    <div class="text-center">
                      <!-- <router-link :to="{ name: 'Dashboard' }"> -->
                      <soft-button class="my-4 mb-2" variant="gradient" color="warning" full-width
                        :disabled="isButtonDisabled">
                        <span v-if="login" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span v-if="login"> Verifying...</span>
                        <span v-if="!login">Login</span>

                      </soft-button>

                      <!-- </router-link> -->
                    </div>
                  </form>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link :to="{ name: 'Sign Up' }" class="text-warning text-gradient font-weight-bold">Sign
                      up</router-link>
                  </p>
                </div> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img/curved-images/contruction.jpeg') +
                    ')',
                }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import axiosInstance from '@/customAxios.js';
// Import your preferred toast theme
import { mapMutations } from "vuex";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "SignIn",
  components: {
    AppFooter,
    SoftInput,
    SoftSwitch,
    SoftButton,
  },
  // setup() {
  //   const toast = useToast();

  //   // Return the toast instance so it can be accessed in methods
  //   return { toast };
  // },
  data() {
    return {
      email: "",
      password: "",
      checked: false,
      login: false,
      isButtonDisabled: false,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async handleSignIn() {
      // Use toast from setup


      this.login = true;
      this.isButtonDisabled = true;

      try {
        const response = await axiosInstance.post('login_user', {
          email: this.email,
          password: this.password,
        });

        const data = response.data;
        // console.log(data);
        const token = data.payload.token;
        const user = data.payload.user;
        user.level = data.payload.level;
        const checkLevel = data.payload.level;
        if (token && checkLevel === 'CONTROL') {
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('branch', JSON.stringify(user.branch_id));

          this.login = false;
          this.isButtonDisabled = false;
          // this.toast.success("Login successful", { position: 'top-right' });

          this.$router.push("/dashboard");
        } else {
          this.login = false;
          this.isButtonDisabled = false;
          // this.toast.error("Not Verifying Credentials", { position: 'top-right' });
        }
      } catch (error) {
        this.login = false;
        this.isButtonDisabled = false;
        // this.toast.error('There was an error!', { position: 'top-right' });
        console.error('There was an error!', error);
      }
    }
  },
};
</script>
