<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- <h1>admin</h1> -->
                <adminStockTable />
            </div>
        </div>
        
    </div>
  </template>
  
  <script>

  import adminStockTable from "../components/admin/stockAdmin.vue";
//   import ProjectsTable from "./components/ProjectsTable";
  
  export default {
    name: "admintables",
    components: {
        adminStockTable,
   
    },
  };
  </script>