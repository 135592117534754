<template>
    <!-- Button trigger modal -->
    <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-warning ml-auto" data-toggle="modal" data-target="#exampleModal">
            Add Branch
        </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Branch Form</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="addBranch">

                        <div class="form-group">
                            <label for="inputAddress2">
                                Branch Name</label>
                            <input type="text" v-model="branch_name" class="form-control" id="inputAddress2"
                                placeholder="service name ">
                        </div>

                        <div class="d-flex justify-content-between mt-4">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-warning">Submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authAxios from '../../../../authAxios';

export default {
    name: "addStockForm",
    components: {
        // textArea,
    },
    data() {
        return {
            branch_name: "",
        }
    },
    methods: {
       
        async addBranch() {
            await authAxios
                .post("add_branch", {branch_name: this.branch_name})
                .then((resp) => {
                    console.log("data", resp);
                    if (resp.data.status === "SUCCESS") {
                        this.branch_name = ""
                            // this.toast.success('service added successfully');
                    } else {
                        console.log(resp.data);
                        // this.toast.error(resp.data.status + " something missing");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

        }
    }
}
</script>