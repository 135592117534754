<template>
  <div class="fixed-plugin">
    <a class="px-3 py-1 fixed-plugin-button text-dark position-fixed d-flex flex-column align-items-center"
      style="background-color: #fbcf33;" @click="toggle">
      <i class="cart-number -mb-4">2</i>
      <i class="py-2 fa fa-cart-plus"></i>
    </a>

    <div class="shadow-lg card blur ">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Shopping Cart</h5>
          <p>See our dashboard options.</p>
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="mt-4">

        <ul class="list-group border-none">
          <li class="list-group-item d-flex justify-content-between align-items-center no-border shadow-lg mb-2">
            <div class="d-flex align-items-center">
              <img
                  :src="imageUrl || placeholderUrl"
                  alt="Product Image"
                  class="img-fluid me-2 rounded"
                  style="width: 60px; height: 60px;"
                />
              <div>
                <h6 class="text-sm">Product 2</h6>
                <div class="d-flex align-items-center">
                  <i class="fas fa-minus-circle text-dark me-2" @click="decreaseQuantity(2)"
                    style="cursor: pointer;"></i>
                  <span class="text-sm mb-0 ">Qty: 1 x $15.00</span>
                  <i class="fas fa-plus-circle text-success ms-2" @click="increaseQuantity(2)"
                    style="cursor: pointer;"></i>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="badge text-dark me-5">$100000</span>

              <i class="fas fa-trash-alt text-danger me-1" @click="deleteItem(2)" style="cursor: pointer;"></i>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center no-border shadow-lg mb-2">
            <div class="d-flex align-items-center">
              <img
                  :src="imageUrl || placeholderUrl"
                  alt="Product Image"
                  class="img-fluid me-2 rounded"
                  style="width: 60px; height: 60px;"
                />
           
              <div>
                <h6 class="text-sm">Product 2</h6>
                <div class="d-flex align-items-center">
                  <i class="fas fa-minus-circle text-dark me-2" @click="decreaseQuantity(2)"
                    style="cursor: pointer;"></i>
                  <span class="text-sm mb-0 ">Qty: 1 x $15.00</span>
                  <i class="fas fa-plus-circle text-success ms-2" @click="increaseQuantity(2)"
                    style="cursor: pointer;"></i>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="badge text-dark me-5">$100000</span>

              <i class="fas fa-trash-alt text-danger me-1" @click="deleteItem(2)" style="cursor: pointer;"></i>
            </div>
          </li>
        </ul>




        <div class="mt-1 shadow-lg mb-2 px-2 py-2">
          <h6>Total: $55.00</h6>
          <a class="btn btn-sm btn-block btn-success" :href="checkoutUrl">Proceed to Checkout</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapMutations, mapActions } from "vuex";
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
export default {
  name: "configurator",
  props: ["toggle"],
  components: {
    DataTable,
    Column
  },
  data() {
    return {
      fixedKey: "",
      imageUrl: '', 
      // imageUrl: 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-chair.jpg', 
      // Placeholder image URL
      placeholderUrl: 'https://placehold.jp/FFDAB9/ffffff/60x60.png?text=Yolum%20ltd'
    };
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor"]),

    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },

    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },

    sidenavTypeOnResize() {
      let transparent = document.querySelector("#btn-transparent");
      let white = document.querySelector("#btn-white");
      if (window.innerWidth < 1200) {
        transparent.classList.add("disabled");
        white.classList.add("disabled");
      } else {
        transparent.classList.remove("disabled");
        white.classList.remove("disabled");
      }
    },
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    // Deactivate sidenav type buttons on resize and small screens
    window.addEventListener("resize", this.sidenavTypeOnResize);
    window.addEventListener("load", this.sidenavTypeOnResize);
  },
};
</script>
<style scoped>
.fixed-plugin-button {
  /* General styles for the button */
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
}

.fixed-plugin-button i {
  font-size: 1.5rem;
  /* Adjust the size of the cart icon */
}

.cart-number {
  margin-top: -22px;
  /* Move the "0" upwards */
  margin-right: -25px;
  font-size: 1rem;
  /* Adjust the size of the number "0" */
  font-weight: lighter;
  font-style: normal;
  font-family: monospace;
  text-align: center;
  background-color: rgb(230, 169, 14);
  border-radius: 50%;
  padding: 5px;
  line-height: 1;
  color: #333;
  /* Color for the "0" */
}

.fa-cart-plus {
  font-size: 2rem;
  /* Size of the cart icon */
}

.border-none {
  border: none !important;
}

.no-border {
  border: none !important;
  box-shadow: none;
}
</style>