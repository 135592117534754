// store/cart.js
export default {
  namespaced: true,  // This ensures the module is namespaced
  state: {
    cart: []
  },
  mutations: {
    ADD_TO_CART(state, product) {
      state.cart.push(product);
    }
  },
  actions: {
    addToCart({ commit }, product) {
      alert("Added to cart: " + product.name);
      commit('ADD_TO_CART', product);
    }
  },
  getters: {
    cartItems(state) {
      return state.cart;
    }
  }
};
