<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <projects-table />
            </div>
        </div>
      
    </div>
</template>
<script>
import SaleTable from "./components/SaleTable";
import ProjectsTable from "./components/ProjectsTable";


export default {
    name: "tables",
    components: {
        SaleTable,
        ProjectsTable,
       
    },
   
  
};
</script>
