<template>
    <!-- Button trigger modal -->
    <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-warning ml-auto" data-toggle="modal" data-target="#exampleModal">
            Capital
        </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Capital Form</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="addCapital">
                        <div class="form-group">
                            <label for="inputAddress2">
                                Choose Branch</label>
                            <select v-model="branch" class="form-control my-1 mr-sm-2">
                                <option>Choose Branch...</option>
                                <option v-for="option in branchList" :key="option.value" :value="option">
                                    {{ option.branch_name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="inputAddress2">
                                Capital</label>
                            <input type="number" v-model="capital" class="form-control" id="inputAddress2"
                                placeholder="1000,0000">
                        </div>
                        <div class="d-flex justify-content-between mt-4">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-warning">Submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authAxios from '../../../../authAxios';

export default {
    name: "addCapitalForm",
    components: {
        // textArea,
    },
    data() {
        return {
            branchList: null,
            branch: '',
            branch_id: '',
            capital: '',
        }
    },
    created() {
        this.listBranch();
    },
    methods: {
        async listBranch() {
            authAxios.post("list_branch").then((resp) => {
                this.branchList = resp.data.payload
                console.log(this.branchList, "branches form")
            }).catch((err) => {
                console.log(err, "branches")
            })
        },
        async addCapital() {

            const data = {
                branch: this.branch.branch_name,
                branch_id: this.branch.id,
                capital: this.capital
            }

            await authAxios
                .post("add_capital", data)
                .then((resp) => {
                    console.log("data", resp);
                    if (resp.data.status === "SUCCESS") {
                           this.branch = '',
                            this.branch_id = '',
                            this.capital = ''
                        // this.toast.success('service added successfully');
                    } else {
                        console.log(resp.data);
                        // this.toast.error(resp.data.status + " something missing");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })


        }
    }
}
</script>