<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Capital Details</h6>
      <AddForm />
    </div>
    <div class="card-body px-0 pb-2 p-3" style="height: 740px;">
      <div style="max-height: 700px; overflow-y: auto;">
        <table class="table align-items-center mb-0">

          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Branch 
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Capital
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Working Capital
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Add By
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Created Date
              </th>
              
              <th class="text-secondary opacity-7">

              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="p-2" v-for="(capital, index) in capitalList" :key="index">
              <td class="px-4">
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ capital.branch.branch_name}}</h6>

                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{ capital.capital }}</p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{ capital.working_capital }}</p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0"> {{capital.add_by.name }}</p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0"> {{moment(capital.created_at).calendar() }}</p>
              </td>
              <td class="align-middle">
              <td class="align-middle text-center text-sm">
                <soft-badge color="warning" variant="gradient" size="sm">EDIT</soft-badge>
              </td>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>
<script>
import AddForm from '../../components/admin/settingForms/AddCapital.vue';
import moment from 'moment';
import authAxios from "../../../authAxios";
export default {
  name: "BranchDetails",
  components: { AddForm },

  data() {
    return {
      capitalList: null,
    };
  },
  created() {
    this.listBranch()
  },
  methods: {
    moment,
    async listBranch() {
      authAxios.post("branches").then((resp) => {
        this.capitalList = resp.data.payload
        console.log(this.capitalList, "capital data")
      }).catch((err) => {
        console.log(err, "capital data")
      })
    },
  },
};
</script>