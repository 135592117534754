<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <!-- <h6>Projects table</h6> -->
      <AddProject />
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <!-- <div class="table-responsive p-0">

        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr >
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                project
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Budget
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
              Paid amount
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
              start date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
              end date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
              deadline
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
              client
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Status
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Completion
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(project, index) in projects" :key="index" @click="openModal(project)">
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img
                    src="../../assets/img/small-logos/logo-jira.svg"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="jira"
                    />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">{{project.name}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">UGX&nbsp;{{ project.budget_amount }}/=</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{ project.paid_amount }}</span>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{ project.start_date }}</span>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{ project.end_date }}</span>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{ project.deadline }}</span>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{ project.clients[0].fullname }}</span>
              </td>
              
              <td>
                <span class="text-xs font-weight-bold">{{ project.status }}</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">{{ project.percentage }}%</span>
                  <div>
                    <soft-progress
                      color="info"
                      variant="gradient"
                      :percentage="60"
                    />
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <button class="btn btn-link text-secondary mb-0">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img
                      src="../../assets/img/small-logos/logo-invision.svg"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="invision"
                    />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">Invision</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">$5,000</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">done</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">100%</span>
                  <div>
                    <soft-progress
                      color="success"
                      variant="gradient"
                      :percentage="100"
                    />
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-link text-secondary mb-0"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img
                      src="../../assets/img/small-logos/logo-jira.svg"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="jira"
                    />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">Jira</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">$3,400</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">canceled</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">30%</span>
                  <div>
                    <soft-progress
                      color="danger"
                      variant="gradient"
                      :percentage="30"
                    />
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-link text-secondary mb-0"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img
                      src="../../assets/img/small-logos/logo-slack.svg"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="slack"
                    />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">Slack</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">$1,000</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">canceled</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">0%</span>
                  <div>
                    <soft-progress
                      color="success"
                      variant="gradient"
                      :percentage="0"
                    />
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-link text-secondary mb-0"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img
                      src="../../assets/img/small-logos/logo-invision.svg"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="webdev"
                    />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">Webdev</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">$14,000</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">working</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">80%</span>
                  <div>
                    <soft-progress
                      color="info"
                      variant="gradient"
                      :percentage="80"
                    />
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-link text-secondary mb-0"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img
                      src="../../assets/img/small-logos/logo-xd.svg"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="xd"
                    />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">Adobe XD</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0">$2,300</p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">done</span>
              </td>
              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">100%</span>
                  <div>
                    <soft-progress
                      color="success"
                      variant="gradient"
                      :percentage="100"
                    />
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-link text-secondary mb-0"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <TreeTable :value="nodes" :tableProps="{ style: { minWidth: '650px' } }" style="overflow: auto"
        :expandedKeys.sync="expandedKeys">
        <Column v-for="col in columns" :key="col.field" :field="col.field" :header="col.header" :expander="col.expander"
          :body="bodyTemplate" />
      </TreeTable>
    </div>
  </div>
</template>

<script>
import SoftProgress from "@/components/SoftProgress";
import AddProject from "./admin/addProject.vue";
import authAxios from '../../authAxios.js';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';

export default {
  name: "projects-table",
  components: {
    SoftProgress,
    AddProject,
    TreeTable,
    Column
  },

  data() {
    return {
      projects: [],
      nodes: [], // Initially empty, will be populated by API call
      expandedKeys: {}, // This will hold the expanded keys for the rows
      columns: [
        { field: 'field1', header: 'Project', expander: true },
        { field: 'field2', header: 'Description' },
        { field: 'field3', header: 'Start Date' },
        { field: 'field4', header: 'End Date' },
        { field: 'field5', header: 'Status' },
        { field: 'field6', header: 'Created At' }
      ]
    }
  },
  created() {
    this.listProject();
  },
  methods: {
    async listProject() {
      try {
        const response = await authAxios.post('list_project');

        // Process the response data
        this.nodes = response.data.payload.map(project => {
          return {
            key: project.id, // Unique key for each node to track expansion
            data: {
              field1: project.name,
              field2: project.description,
              field3: project.start_date,
              field4: project.end_date,
              field5: project.status || 'N/A',
              field6: project.created_at
            },
            children: [
              {
                data: { field1: 'Clients', field2: 'Email', field3: 'Phone' },
                children: project.clients.map(client => ({
                  data: {
                    field1: client.fullname,
                    field2: client.email,
                    field3: client.tel || 'N/A'
                  }
                }))
              },
              {
                data: { field1: 'Transactions', field2: 'Amount', field3: 'Transaction Date', field4: 'Payment Status' },
                children: project.transactions.map(transaction => ({
                  data: {
                    field1: transaction.transaction_id,
                    field2: transaction.amount,
                    field3: transaction.transaction_date,
                    field4: transaction.payment_status
                  }
                }))
              }
            ]
          };
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    bodyTemplate(rowData, column) {
      // Render data
      return `<span>${rowData[column.field]}</span>`;
    }

  }
};
</script>
